import React, { useRef } from "react";
import { Menubar } from "primereact/menubar";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import "./../assets/styles/header.css";
import maxLogo from "./../assets/images/Max Energy_Colour_RGB.png";

const Header = (props) => {
    const navigate = useNavigate();

    const { data } = props;

    const menuRight = useRef(null);
    const toast = useRef(null);

    const handleLogout = () => {
        navigate("/login");
    }

    const handleProfile = () => {
        navigate("/home/profile", { state: { data: data } });
    }

    const handleClientOverview = () => {
        navigate("/home/dashboard", { state: { data: data } });
    }

    const menuItems = [
        { label: "Profile", icon: "pi pi-fw pi-user", command: handleProfile },
        { separator: true },
        { label: "Logout", icon: "pi pi-fw power-off", command: handleLogout },
    ];

    const items = [
        { label: "Client Portal", command: handleClientOverview },
        { label: "Logout", icon: "pi pi-fw pi-power-off", command: handleLogout }
    ]

    const start = <img alt="logo" src={maxLogo} height="40" className="mr-2"></img>;
    const end = <div style={{ display: "flex" }}>
        <Menu model={menuItems} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
        <div className="pointer" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" >
            <i className="pi pi-user profile-icon"></i>
            <label className="display-name">
                {data.data.clientDetails.userDisplayName}
            </label>
        </div>

    </div>;

    return (
        <div className="card">
            <Toast ref={toast} />
            <Menubar model={items} start={start} end={end} />
        </div>
    )
}

export default Header;