import React from 'react';
import ReactDOM from 'react-dom/client';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css"; 
import "primeicons/primeicons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/login";
import Dashboard from "./components/dashboard";
import Profile from "./components/profile";
import Sites from "./components/sites";
import SiteAttentionList from './components/siteAttentionList';
import Home from "./components/home";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={"/"}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element={<Login />} />
        </Route>

        <Route path="/home/" element={<Home />}>
          <Route path="dashboard" element={<Dashboard/>}></Route>
          <Route path="profile" element={<Profile/>}></Route>
          <Route path="sites" element={<Sites/>}></Route>
          <Route path='siteAttentionList' element={<SiteAttentionList/>}></Route>
        </Route>

        <Route path="/login" element={<Login/>}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
