import Header from './header';
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";

const Home = () => {
    const location = useLocation();
    const { data } = location.state !== null ? location.state : "";

    return (
        <div className='container-fluid'>
            <Header data = {data} />
            <Outlet />
        </div>
    );
}

export default Home;