import React, { Fragment, useRef, useState, useEffect } from "react";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./../assets/styles/profile.css";
import { SERVICE_LOCATION, SAVE_CLIENT_LOGIN } from "./../constants/endpoints"
import { useCallback } from "react";

const Profile = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const toast = useRef(null);

    const { data } = location.state !== null ? location.state : "";

    const [id, setId] = useState(0);
    const [clientAccount, setClientAccount] = useState("");
    const [userDisplayName, setUserDisplayName] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [passwordHash, setPasswordHash] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isPasswordChange, setIsPasswordChange] = useState(false);

    const handleSaveClientLogin = () => {
        try{
            const requestData = {
                id: id,
                clientAccount: clientAccount,
                userName: userName,
                passwordHash: passwordHash,
                userDisplayName: userDisplayName,
                userPhone: userPhone,
                userEmail: userEmail,
                existingPassword: password,
                newPassword: newPassword,
                confirmPassword: confirmPassword,
                isPasswordChange: isPasswordChange
            }
            const endPoint = SERVICE_LOCATION + SAVE_CLIENT_LOGIN;
            axios.post(endPoint, requestData, {
                headers: {
                    Authorization: `Bearer ${data.data.token}`,
                },
            })
            .then((response) => {
                if (response.data.isSuccess) {
                    const updatedData = {
                        token: data.data.token,
                        message: "",
                        clientDetails: {
                            id: id,
                            clientAccount: clientAccount,
                            clientName: data.data.clientDetails.clientName,
                            clientAddress: data.data.clientDetails.clientAddress,
                            userName: userName,
                            passwordHash: response.data.data.passwordHash,
                            userDisplayName: userDisplayName,
                            userPhone: userPhone,
                            userEmail: userEmail
                        }
                    }
                    data.data = updatedData;
                    navigate("/home/dashboard", { state: { data: data } }); 
                    toast.current.show({severity:"success", summary: "Success", detail: response.data.data, life: 5000});                    
                }
                else if (!response.data.isSuccess) {
                    toast.current.show({severity:"error", summary: "Error", detail:response.data.errorMessage, life: 3000});
                }                
            })
            .catch((error) => {
                toast.current.show({severity:"error", summary: "Error", detail:error, life: 3000});
            })
        }
        catch(error){            
            toast.current.show({severity:"error", summary: "Error", detail:error, life: 3000});
        }
    }

    const handleCancelClientLogin = () => {
        setIsPasswordChange(false);
        setPassword("");
        setNewPassword("");
        setConfirmPassword("");        
        navigate("/home/dashboard", { state: { data: data } });
    }

    const onPageLoadData = useCallback(() => {
        setId(data.data.clientDetails.id);
        setClientAccount(data.data.clientDetails.clientAccount);
        setUserDisplayName(data.data.clientDetails.userDisplayName);
        setUserPhone(data.data.clientDetails.userPhone);
        setUserEmail(data.data.clientDetails.userEmail);
        setUserName(data.data.clientDetails.userName);
        setPasswordHash(data.data.clientDetails.passwordHash)
    }, [data.data.clientDetails.id, data.data.clientDetails.clientAccount, data.data.clientDetails.userDisplayName, data.data.clientDetails.userPhone,
        data.data.clientDetails.userEmail, data.data.clientDetails.userName, data.data.clientDetails.passwordHash])

    useEffect(() => {
        onPageLoadData();
    }, [onPageLoadData]); 

    return(
        <Fragment>
            <Toast ref={toast} />
            <div className="padl-100 padt-30">
                <h2 className="padb-30">User Profile</h2>
                <div className="row padb-30">
                    <label className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2" htmlFor="userDisplayName">Display Name</label>
                    <InputText className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 p-inputtext-sm" value={userDisplayName} id="userDisplayName" onChange={(e) => setUserDisplayName(e.target.value)} />
                </div>
                <div className="row padb-30">
                    <label className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2" htmlFor="userPhone">Phone</label>
                    <InputText className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 p-inputtext-sm" value={userPhone} id="userPhone" onChange={(e) => setUserPhone(e.target.value)} />
                </div>
                <div className="row padb-30">
                    <label className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2" htmlFor="userEmail">Email</label>
                    <InputText className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 p-inputtext-sm" value={userEmail} id="userEmail" onChange={(e) => setUserEmail(e.target.value)} />
                </div>
                <div className="row padb-30">
                    <label className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2" htmlFor="userName">User Name</label>
                    <InputText className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 p-inputtext-sm" disabled value={userName} id="userName" onChange={(e) => setUserName(e.target.value)} />
                </div>
                <div className="row padb-30">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <div className="flex flex-column gap-2">
                        <Checkbox className="padr-5" value={isPasswordChange} id="isPasswordChange" onChange={e => setIsPasswordChange(e.checked)} checked={isPasswordChange}></Checkbox>
                            <label htmlFor="isPasswordChange">Change Password</label>                            
                        </div>
                    </div>
                </div>

                {isPasswordChange &&
                    <div id="password-change">
                        <div className="row padb-30">
                            <label className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2" htmlFor="userPassword">Existing Password</label>
                            <input type="password" className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 p-inputtext-sm form-control" id="userPassword" name="userPassword" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className="row padb-30">
                            <label className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2" htmlFor="newPassword">New Password</label>
                            <input type="password" className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 p-inputtext-sm form-control" id="newPassword" name="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                        </div>
                        <div className="row padb-30">
                            <label className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2" htmlFor="confirmPassword">Confirm Password</label>
                            <input type="password" className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 p-inputtext-sm form-control" id="confirmPassword" name="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                        </div>
                    </div>
                }

                <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 txt-right">
                    <Button className="mar-r-20" id="save-client-login" size="small" onClick={handleCancelClientLogin} severity="secondary" outlined>Cancel</Button>
                    <Button id="save-client-login" size="small" onClick={handleSaveClientLogin} outlined>Save</Button>
                </div>
            </div>
        </Fragment>
    )
}

export default Profile;