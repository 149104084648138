import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "primereact/card";
import "../assets/styles/dashboard.css";
import "../assets/styles/table.css";
import axios from "axios";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { SERVICE_LOCATION, CLIENT_OVERVIEW_API } from "./../constants/endpoints"
import { useCallback } from "react";

const Dashboard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const toast = useRef(null);

    const { data } = location.state !== null ? location.state : "";

    const [clientOverview, setClientOverview] = useState(null);
    const [totalSites, setTotalSites] = useState(0);
    const [totalMeasures, setTotalMeasures] = useState(0);
    const [totalSiteAttention, setTotalSiteAttention] = useState(0);
    const [totalValue, setTotalValue] = useState(0.0);
    const [selectedSite, setSelectedSite] = useState(null);
    const [loading, setLoading] = useState(false);

    const getClientOverview = useCallback((clientAccount) => {
        setLoading(true);
        const endPoint = SERVICE_LOCATION + CLIENT_OVERVIEW_API;
        const requestedData = {
            clientAccount: clientAccount
        }
        axios.post(endPoint, requestedData, {
            headers: {
                Authorization: `Bearer ${data.data.token}`,
            },
        })
            .then((response) => {
                setLoading(false);
                if (response.data.isSuccess) {
                    const clientOverview = response.data.data.getClientOverview;
                    setClientOverview(clientOverview);
                    const totSites = clientOverview.length;
                    setTotalSites(totSites.toLocaleString("en-us"));
                    const totMeas = clientOverview.reduce((total, item) => total + item.totalMeasures, 0)
                    setTotalMeasures(totMeas.toLocaleString("en-US"));
                    setTotalSiteAttention(clientOverview.reduce((total, item) => total + item.totalSiteAttention, 0));
                    const totVal = clientOverview.reduce((total, item) => total + item.totalValue, 0);
                    setTotalValue(totVal.toLocaleString("en-US"));
                }
                else if (!response.data.isSuccess) {
                    setClientOverview(null);
                    toast.current.show({ severity: "error", summary: "Error", detail: response.data.errorMessage, life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error, life: 3000 });
            })
    }, [data.data.token])

    const handleShowSiteAttentionMeasures = () => {
        navigate("/home/siteAttentionList", { state: { data: data } });
    }

    const onSiteRowSelect = (event) => {
        data.siteInfo = event.data;
        navigate("/home/sites", { state: { data: data } });
    }

    useEffect(() => {
        getClientOverview(data.data.clientDetails.clientAccount);
    }, [data.data.clientDetails.clientAccount, getClientOverview]);

    // Search functionality
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        systemId: { value: null, matchMode: FilterMatchMode.CONTAINS },
        siteName: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const renderHeader = () => {
        return (
            <div className="row">
                <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                    {totalSiteAttention === 0 ?
                        <label className="padt-15 font-16">No measures require site attention</label> :
                        <div className="padt-15 font-16">
                            <label>You have {totalSiteAttention} worksheet(s) requiring your attention&nbsp;</label>
                            <button className="button-link" onClick={handleShowSiteAttentionMeasures}>Show Me</button>
                        </div>
                    }
                </div>
                <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div className="flex justify-content-end txt-right">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const header = renderHeader();
    // Search functionality Ends here

    return (
        <div className={`app ${loading ? "disabled" : ""}`}>
            {
                loading &&
                <div className="spinner-overlay">
                    <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                </div>
            }

            <Toast ref={toast} />
            <div className="body">
                <div className="card-section">
                    <Card className="card-info">
                        <div>Total Sites</div>
                        <div className="card-data">{totalSites}</div>
                    </Card>

                    <Card className="card-info">
                        <div>Total Measures</div>
                        <div className="card-data">{totalMeasures}</div>
                    </Card>

                    <Card className="card-info">
                        <div>Total Value</div>
                        <div className="card-data">{`£${totalValue}`}</div>
                    </Card>
                </div>

                <div>
                    <DataTable className="font-12" value={clientOverview} dataKey="systemId"
                        paginator rows={10} rowsPerPageOptions={[10, 20, 50]} selectionMode="single"
                        selection={selectedSite} onSelectionChange={(e) => setSelectedSite(e.value)}
                        onRowSelect={onSiteRowSelect} filters={filters} header={header}
                        globalFilterFields={["systemId", "siteName", "totalMeasures", "totalWorksheets"]}
                        scrollable tableStyle={{ minWidth: "70rem" }} emptyMessage="No sites available for the client.">
                        <Column field="systemId" header="SystemId" className="table-header" hidden></Column>
                        <Column field="siteName" header="Site" className="table-header"></Column>
                        <Column field="totalMeasures" header="Total Measures" className="table-header"></Column>
                        <Column field="totalWorksheets" header="Total Worksheets" className="table-header" hidden></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;