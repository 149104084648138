export const LOGIN_API = "api/Login/ClientLogin";
export const CLIENT_OVERVIEW_API = "api/Client/GetClientOverview";
export const SITE_MEASURES_API = "api/Site/GetSiteMeasures";
export const SITE_ATTENTION_LIST_BY_CLIENTACCOUNT_API = "api/Site/GetSiteAttentionListByClientAccount";
export const SITE_ATTENTION_MEASURES_BY_CLIENTACCOUNT = "api/Site/GetSiteAttentionMeasuresByClientAccount";
export const WORKCARD_DETAILS_BY_SUBMISSION_ID = "api/Submission/GetWorkCardDetailsBySubmissionId";
export const SAVE_CLIENT_LOGIN = "api/Login/SaveClientDetails";
export const DOWNLOAD_SUBMITTED_WORKSHEET = "api/Site/DownloadSubmittedWorksheet";
export const SIGN_TO_APPROVE = "api/Submission/SignFormToApprove";

export const SERVICE_LOCATION = "https://eeg-clientportalapi.azurewebsites.net/";   //https://localhost:7107/   //https://eeg-clientportalapi.azurewebsites.net/