import React, { Fragment, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import axios from "axios";
import "../assets/styles/login.css"
import { Toast } from "primereact/toast";
import { SERVICE_LOCATION, LOGIN_API } from "./../constants/endpoints"

const Login = () => {    
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    
    const toast = useRef(null);

    const navigate = useNavigate();

    const userLogin = () => {
        try{
            const requestData = {
                userName: userName,
                password: password
            }
            const endPoint = SERVICE_LOCATION + LOGIN_API;
            axios.post(endPoint, requestData)
                .then((response) => {
                    if(response.data.isSuccess){
                        navigate("/home/dashboard", { state: { data: response.data } });                        
                    }
                    else if(!response.data.isSuccess){
                        toast.current.show({severity:"error", summary: "Error", detail:response.data.errorMessage, life: 3000});
                    }
                })
                .catch((error) => {
                    toast.current.show({severity:"error", summary: "Error", detail:error, life: 3000});
                })            
        }
        catch(error){            
            toast.current.show({severity:"error", summary: "Error", detail:error, life: 3000});
        }
    }

    const handleUserNameChange = (e) => {
        setUserName(e.target.value);
    };
    
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleLogin = (e) => {
        e.preventDefault();
        userLogin();    
    }

    return (
        <Fragment>
            <Toast ref={toast} />
            <div className="login-background">
                <div>
                    <div className="logo"></div>

                    <label id="lbl-username" className="text-label mrb-10">Username</label>
                    <input type="text" className="form-control mrb-10" id="txt-username" name="username" value={userName} onChange={handleUserNameChange} />

                    <label id="lbl-password" className="text-label mrb-10">Password</label>
                    <input type="password" className="form-control mrb-10" id="txt-password" name="password" value={password} onChange={handlePasswordChange} />

                    <Button className="btn-success mrt-10 mrb-10" id="btn-login" onClick={handleLogin}>Login</Button>
                </div>
            </div>
        </Fragment>
    )
}

export default Login;