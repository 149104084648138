import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../assets/styles/dashboard.css";
import "../assets/styles/table.css";
import axios from "axios";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { TabMenu } from "primereact/tabmenu";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import SignatureCanvas from "react-signature-canvas";
import { SERVICE_LOCATION, SITE_MEASURES_API, DOWNLOAD_SUBMITTED_WORKSHEET, SIGN_TO_APPROVE } from "./../constants/endpoints"
import { WORKCARDS_DOWNLOADED } from "./../constants/errors";
import { useCallback } from "react";

const Sites = () => {
    const location = useLocation();
    const toast = useRef(null);
    const signatureRef = useRef(null);

    const { data } = location.state !== null ? location.state : "";

    const [siteMeasures, setSiteMeasures] = useState(null);
    const [totalMeasures, setTotalMeasures] = useState(0);
    const [totalPlots, setTotalPlots] = useState(0);
    const [totalSiteAttention, setTotalSiteAttention] = useState(0);
    const [filteredMeasures, setFilteredMeasures] = useState(null);
    const [selectedCompletedMeasures, setSelectedCompletedMeasures] = useState(null);
    const [measureStatus, setMeasureStatus] = useState("");
    const [selectedMeasure, setSelectedMeasure] = useState(null);
    const [disableDownloadButton, setDisableDownloadButton] = useState(true);
    const [loading, setLoading] = useState(false);

    const [showPdf, setShowPdf] = useState(false);
    const [showSignatureDialouge, setShowSignatureDialouge] = useState(false);
    const [base64Pdf, setBase64Pdf] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [minDate, setMinDate] = useState(null);

    const handlePendingMeasures = () => {
        const pendingMeasures = siteMeasures.filter((item) => item.measureStatus === "NEW");
        setFilteredMeasures(pendingMeasures);
        setMeasureStatus("Pending Measures");
    }

    const handleInprogressMeasures = () => {
        const voidMeasures = siteMeasures.filter((item) => item.measureStatus === "CALLED OFF");
        setFilteredMeasures(voidMeasures);
        setMeasureStatus("Inprogress Measures");
    }

    const handleCompletedMeasures = () => {
        const completedMeasures = siteMeasures.filter((item) => item.measureStatus === "COMPLETE");
        completedMeasures.sort((a, b) => b.submissionId - a.submissionId);
        setFilteredMeasures(completedMeasures);
        setSelectedCompletedMeasures(null);
        setMeasureStatus("Completed Measures");
    }

    const handleInvoicedMeasures = () => {
        const querMeasures = siteMeasures.filter((item) => item.measureStatus === "INVOICED" || item.measureStatus === "APPLIED FOR");
        setFilteredMeasures(querMeasures);
        setMeasureStatus("Invoiced Measures");
    }

    const handleVoidMeasures = () => {
        setMeasureStatus("Void Measures");
        const querMeasures = siteMeasures.filter((item) => item.measureStatus === "CANCELLED");
        setFilteredMeasures(querMeasures);
    }

    const handleSiteAttention = () => {
        const querMeasures = siteMeasures.filter((item) => item.submissionStatus === "Site Attention");
        setFilteredMeasures(querMeasures);
        setMeasureStatus("Site Attention Measures");
    }

    const items = [
        { label: "Pending Measures", command: handlePendingMeasures },
        { label: "Inprogress Measures", command: handleInprogressMeasures },
        { label: "Completed Measures", command: handleCompletedMeasures },
        { label: "Invoiced Measures", command: handleInvoicedMeasures },
        { label: "Void Measures", command: handleVoidMeasures },
        { label: "Site Attention Measures", command: handleSiteAttention }
    ];

    const getSiteMeasures = useCallback((systemId, isPending) => {
        const endPoint = SERVICE_LOCATION + SITE_MEASURES_API;
        const requestedData = {
            systemId: systemId
        }
        axios.post(endPoint, requestedData, {
            headers: {
                Authorization: `Bearer ${data.data.token}`,
            },
        })
            .then((response) => {
                if (response.data.isSuccess) {
                    const siteMeasures = response.data.data.getSiteMeasures;

                    if (siteMeasures.length > 0) {
                        var allMeasures = siteMeasures.map(function (item) {
                            const calloffRequestedDateOnly = (item.calloffRequested).substring(0, (item.calloffRequested).indexOf("T"));
                            const measureCompletedDateOnly = (item.measureCompleted).substring(0, (item.measureCompleted).indexOf("T"));
                            return { ...item, calloffRequested: calloffRequestedDateOnly === "1900-01-01" ? "" : calloffRequestedDateOnly, measureCompleted: measureCompletedDateOnly === "1900-01-01" ? "" : measureCompletedDateOnly };
                        });

                        setSiteMeasures(allMeasures);
                        const totMeas = allMeasures.length;
                        setTotalMeasures(totMeas.toLocaleString("en-us"));

                        const uniquePlots = new Set(allMeasures.map((item) => item.plotNumber));
                        const plotsCounts = uniquePlots.size;
                        setTotalPlots(plotsCounts.toLocaleString("en-us"));

                        const siteAttentionCount = allMeasures.filter((item) => item.submissionStatus === "Site Attention").length;
                        setTotalSiteAttention(siteAttentionCount.toLocaleString("en-us"));

                        if (isPending) {
                            setMeasureStatus("Pending Measures");
                            const pendingMeasures = allMeasures.filter((item) => item.measureStatus === "NEW");
                            setFilteredMeasures(pendingMeasures);
                        }
                        else {
                            setMeasureStatus("Site Attention Measures");
                            const siteAttentionMeasures = allMeasures.filter((item) => item.measureStatus === "Site Attention");
                            setFilteredMeasures(siteAttentionMeasures);
                            setShowSignatureDialouge(false);
                            setShowPdf(false);
                        }
                    }
                    else {
                        setSiteMeasures(null);
                        setTotalMeasures(0);
                        setTotalPlots(0);
                        setTotalSiteAttention(0);
                        if (isPending) {
                            setMeasureStatus("Pending Measures");
                        }
                        else {
                            setMeasureStatus("Site Attention Measures");
                        }
                    }
                }
                else if (!response.data.isSuccess) {
                    setSiteMeasures(null);
                    toast.current.show({ severity: "error", summary: "Error", detail: response.data.errorMessage, life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error, life: 3000 });
            })
    }, [data.data.token])

    const handlePDFDownload = () => {
        setLoading(true);
        var selectedSubmissions = [];
        selectedCompletedMeasures.forEach(worksheet => {
            const submission = {
                submissionId: worksheet.submissionId
            }
            selectedSubmissions.push(submission);
        })
        const requestedData = {
            isApproveForm: false,
            getWorkCardsRequestDTO: selectedSubmissions
        }
        const endPoint = SERVICE_LOCATION + DOWNLOAD_SUBMITTED_WORKSHEET;
        axios.post(endPoint, requestedData, {
            headers: {
                Authorization: `Bearer ${data.data.token}`,
            },
        })
            .then((response) => {
                setLoading(false);
                if (response.data.isSuccess) {
                    if (response.data.data.downloadedWorkCards.length > 0) {
                        (response.data.data.downloadedWorkCards).forEach(workcard => {
                            const base64String = "data:application/pdf;base64," + workcard.workCardBase64String;
                            const linkSource = base64String;
                            const downloadLink = document.createElement("a");
                            const fileName = workcard.fileName;
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                        });
                    }
                    toast.current.show({ severity: "success", summary: "success", detail: WORKCARDS_DOWNLOADED, life: 5000 });
                }
                else if (!response.data.isSuccess) {
                    toast.current.show({ severity: "error", summary: "Error", detail: response.data.errorMessage, life: 5000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error, life: 5000 });
            })
    }

    const handleCompletedMeasuresSelection = (e) => {
        setSelectedCompletedMeasures(e.value);
        e.value.length > 0 ? setDisableDownloadButton(false) : setDisableDownloadButton(true);
    }

    const approveWorkcard = (siteAttentionData) => {
        setLoading(true);
        const today = new Date();
        setMinDate(today);
        setSelectedMeasure(siteAttentionData);
        var selectedSubmissions = []
        const submission = {
            submissionId: siteAttentionData.submissionId
        }
        selectedSubmissions.push(submission);
        const requestedData = {
            isApproveForm: true,
            getWorkCardsRequestDTO: selectedSubmissions
        }
        const endPoint = SERVICE_LOCATION + DOWNLOAD_SUBMITTED_WORKSHEET;
        axios.post(endPoint, requestedData, {
            headers: {
                Authorization: `Bearer ${data.data.token}`,
            },
        })
            .then((response) => {
                setLoading(false);
                if (response.data.isSuccess) {
                    if (response.data.data.downloadedWorkCards.length > 0) {
                        (response.data.data.downloadedWorkCards).forEach(workcard => {
                            setBase64Pdf(workcard.workCardBase64String);
                            setShowPdf(true);
                            setShowSignatureDialouge(false);
                        });
                    }
                }
                else if (!response.data.isSuccess) {
                    toast.current.show({ severity: "error", summary: "Error", detail: response.data.errorMessage, life: 5000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error, life: 5000 });
            })
    }

    const getFormattedDate = (dateToFormat) => {
        const year = dateToFormat.getFullYear();
        const month = String(dateToFormat.getMonth() + 1).padStart(2, "0");
        const day = String(dateToFormat.getDate()).padStart(2, "0");
        const formatedDate = `${year}-${month}-${day}`;
        return formatedDate;
    }

    const handleShowSiteAttentionMeasure = () => {
        setShowPdf(false);
    }

    const showSignToApprove = () => {
        setShowSignatureDialouge(true);
    }

    const onModalClear = () => {
        signatureRef.current.clear();
        setSelectedDate(null);
    }

    const onModalClose = () => {
        onModalClear();
        setShowSignatureDialouge(false);
    }

    const onModalSubmit = () => {
        var base64SignatureString = "";
        if (signatureRef.current.isEmpty()) {
            toast.current.show({ severity: "error", summary: "Error", detail: "Please provide a signature before saving.", life: 3000 });
        }
        else {
            var signatureText = signatureRef.current.getTrimmedCanvas().toDataURL("image/png");
            const splitArray = signatureText.split("data:image/png;base64,");
            if (splitArray.length === 2) {
                base64SignatureString = splitArray[1];
            }
            const formatedDate = getFormattedDate(selectedDate);
            const endPoint = SERVICE_LOCATION + SIGN_TO_APPROVE;
            const requestedData = {
                submissionId: selectedMeasure.submissionId,
                managerName: data.data.clientDetails.userDisplayName,
                signedDate: formatedDate,
                base64SignatureString: base64SignatureString
            }
            axios.post(endPoint, requestedData, {
                headers: {
                    Authorization: `Bearer ${data.data.token}`,
                },
            })
                .then((response) => {
                    if (response.data.isSuccess) {
                        onModalClear();
                        getSiteMeasures(data.siteInfo.systemId, false);
                        var successMsg = "Approved sleected measure";
                        toast.current.show({ severity: "success", summary: "Success", detail: successMsg, life: 3000 });
                    }
                    else if (!response.data.isSuccess) {
                        toast.current.show({ severity: "error", summary: "Error", detail: response.data.errorMessage, life: 3000 });
                    }
                })
                .catch((error) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: error, life: 3000 });
                })
        }
    }

    const handleDateChange = (e) => {
        const dateSelected = e.value;
        setSelectedDate(dateSelected);
    }

    useEffect(() => {
        getSiteMeasures(data.siteInfo.systemId, true);
    }, [data.siteInfo.systemId, getSiteMeasures]);

    // To disbale unsubmitted worksheets to prevent from download
    const isSelectable = (data) => (data.submissionId !== 0) && data.measureStatus === "COMPLETE";
    const isRowSelectable = (event) => (event.data ? isSelectable(event.data) : true);
    const rowClassName = (data) => (isSelectable(data) ? "" : "p-disabled");
    // Disable functionality Ends here

    // Search functionality
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        plotNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        worksheetCode: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const renderHeader = () => {
        return (
            <div className="row">
                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <h4 className="status-header">{measureStatus}</h4>
                </div>
                <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                    <div className="flex justify-content-end txt-right">
                        {
                            measureStatus === "Completed Measures" &&
                            <Button className="btn-secondary" outlined onClick={handlePDFDownload} disabled={disableDownloadButton}>Download Worksheet(s) PDF</Button>
                        }

                        <span className="padr-20"></span>
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const header = renderHeader();
    // Search functionality Ends here

    const handleApproveLink = (rowdata) => {
        return <Button label="Approve" onClick={() => approveWorkcard(rowdata)} link style={{ fontSize: "12px", paddingLeft: "0px" }} />
    }

    const footerContent = (
        <div>
            <Button label="Clear" size="small" onClick={onModalClear} severity="secondary" outlined />
            <Button label="Cancel" size="small" onClick={onModalClose} severity="secondary" outlined />
            <Button label="Approve" size="small" onClick={onModalSubmit} outlined />
        </div>
    );

    return (
        <div >
            <Toast ref={toast} />
            <div className={`body ${loading ? "disabled" : ""}`}>
                {
                    loading &&
                    <div className="spinner-overlay">
                        <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                    </div>
                }
                <h2>Site: {data.siteInfo.siteName}({data.siteInfo.systemId})</h2>
                <div className="card-section">
                    <Card className="card-info">
                        <div>Total Measures</div>
                        <div className="card-data">{totalMeasures}</div>
                    </Card>

                    <Card className="card-info">
                        <div>Total Plots</div>
                        <div className="card-data">{totalPlots}</div>
                    </Card>

                    <Card className="card-info">
                        <div>Site Attention</div>
                        <div className="card-data">{totalSiteAttention}</div>
                    </Card>
                </div>
                <div>
                    <TabMenu model={items} />
                    {
                        measureStatus !== "Completed Measures" && measureStatus !== "Site Attention Measures" &&
                        <DataTable className="font-12" value={filteredMeasures} dataKey="id"
                            paginator rows={10} rowsPerPageOptions={[10, 20, 50]} filters={filters} header={header}
                            globalFilterFields={["id", "plotId", "plotNumber", "plotDescription", "measureDescription", "measureQuantity",
                                "worksheetCode", "locationCode", "totalRevenue", "payyAppNo", "measureStatus", "measureContractNumber",
                                "calloffRequested", "measureCompleted"]}
                            scrollable tableStyle={{ minWidth: "70rem" }} emptyMessage="No measures available for the site.">
                            <Column field="id" header="Id" className="table-header" hidden></Column>
                            <Column field="plotId" header="PlotId" className="table-header" hidden></Column>
                            <Column field="plotNumber" header="Plot Number" className="table-header"></Column>
                            <Column field="plotDescription" header="Plot Description" className="table-header"></Column>
                            <Column field="measureDescription" header="Measure Description" className="table-header"></Column>
                            <Column field="measureQuantity" header="Measure Quantity" className="table-header"></Column>
                            <Column field="worksheetCode" header="Worksheet Code" className="table-header"></Column>
                            <Column field="locationCode" header="Location" className="table-header"></Column>
                            <Column field="totalRevenue" header="Measure Price" className="table-header"></Column>
                            <Column field="payyAppNo" header="App No" className="table-header"></Column>
                            <Column field="measureStatus" header="Status" className="table-header"></Column>
                            <Column field="measureContractNumber" header="OrderNumber" className="table-header"></Column>
                            <Column field="calloffRequested" header="Calloff Requested" className="table-header"></Column>
                            <Column field="measureCompleted" header="Completed Date" className="table-header"></Column>
                        </DataTable>
                    }
                    {
                        measureStatus === "Completed Measures" &&
                        <DataTable className="font-12" value={filteredMeasures} dataKey="id"
                            paginator rows={10} rowsPerPageOptions={[10, 20, 50]} filters={filters} header={header}
                            selectionMode="checkbox" selection={selectedCompletedMeasures} onSelectionChange={handleCompletedMeasuresSelection}
                            globalFilterFields={["id", "plotId", "plotNumber", "plotDescription", "measureDescription", "measureQuantity",
                                "worksheetCode", "locationCode", "totalRevenue", "payyAppNo", "measureStatus", "measureContractNumber",
                                "calloffRequested", "measureCompleted"]} isDataSelectable={isRowSelectable} rowClassName={rowClassName}
                            scrollable tableStyle={{ minWidth: "70rem" }} emptyMessage="No measures available for the site.">
                            <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                            <Column field="id" header="Id" className="table-header" hidden></Column>
                            <Column field="plotId" header="PlotId" className="table-header" hidden></Column>
                            <Column field="plotNumber" header="Plot Number" className="table-header"></Column>
                            <Column field="plotDescription" header="Plot Description" className="table-header"></Column>
                            <Column field="measureDescription" header="Measure Description" className="table-header"></Column>
                            <Column field="measureQuantity" header="Measure Quantity" className="table-header"></Column>
                            <Column field="worksheetCode" header="Worksheet Code" className="table-header"></Column>
                            <Column field="locationCode" header="Location" className="table-header"></Column>
                            <Column field="totalRevenue" header="Measure Price" className="table-header"></Column>
                            <Column field="payyAppNo" header="App No" className="table-header"></Column>
                            <Column field="measureStatus" header="Status" className="table-header"></Column>
                            <Column field="measureContractNumber" header="OrderNumber" className="table-header"></Column>
                            <Column field="calloffRequested" header="Calloff Requested" className="table-header"></Column>
                            <Column field="measureCompleted" header="Completed Date" className="table-header"></Column>
                        </DataTable>
                    }
                    {
                        measureStatus === "Site Attention Measures" &&
                        <DataTable className="font-12" value={filteredMeasures} dataKey="id"
                            paginator rows={10} rowsPerPageOptions={[10, 20, 50]} filters={filters} header={header}
                            globalFilterFields={["id", "plotId", "plotNumber", "plotDescription", "measureDescription", "measureQuantity",
                                "worksheetCode", "locationCode", "totalRevenue", "payyAppNo", "measureStatus", "measureContractNumber",
                                "calloffRequested", "measureCompleted"]}
                            scrollable tableStyle={{ minWidth: "70rem" }} emptyMessage="No measures available for the site.">
                            <Column header="Approve" body={handleApproveLink} />
                            <Column field="id" header="Id" className="table-header" hidden></Column>
                            <Column field="plotId" header="PlotId" className="table-header" hidden></Column>
                            <Column field="plotNumber" header="Plot Number" className="table-header"></Column>
                            <Column field="plotDescription" header="Plot Description" className="table-header"></Column>
                            <Column field="measureDescription" header="Measure Description" className="table-header"></Column>
                            <Column field="measureQuantity" header="Measure Quantity" className="table-header"></Column>
                            <Column field="worksheetCode" header="Worksheet Code" className="table-header"></Column>
                            <Column field="locationCode" header="Location" className="table-header"></Column>
                            <Column field="totalRevenue" header="Measure Price" className="table-header"></Column>
                            <Column field="payyAppNo" header="App No" className="table-header"></Column>
                            <Column field="measureStatus" header="Status" className="table-header"></Column>
                            <Column field="measureContractNumber" header="OrderNumber" className="table-header"></Column>
                            <Column field="calloffRequested" header="Calloff Requested" className="table-header"></Column>
                            <Column field="measureCompleted" header="Completed Date" className="table-header"></Column>
                        </DataTable>
                    }
                    <Dialog header="APPROVE MEASURE" style={{ width: "310vw" }} visible={showPdf} onHide={handleShowSiteAttentionMeasure}>
                        <div>
                            <div className="row">
                                <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <Button id="show-siteattention-measures" label="Show SiteAttention Measures" link style={{ fontSize: "12px", paddingLeft: "0px" }} onClick={handleShowSiteAttentionMeasure} />
                                </div>
                                <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 txt-right padb-10">
                                    <Button id="add-fitter" label="Sign to Approve" size="small" outlined onClick={showSignToApprove} />
                                </div>
                            </div>

                            <div>
                                <iframe title="PDF Viewer" width="100%" height="500px" src={`data:application/pdf;base64,${base64Pdf}`} />
                            </div>
                        </div>
                    </Dialog>

                    <Dialog header="SITE AGENT DECLARATION" style={{ width: "50vw" }} visible={showSignatureDialouge} onHide={onModalClose} footer={footerContent}>
                        <div className="signature-dialouge">
                            <div className="row">
                                <lable className="col-12 border-col worksheet-field height-30">I confirm that the above work has been completed to my satisfaction.</lable>
                            </div>

                            <div className="row">
                                <label className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 border-col pad-center worksheet-field">Site Agent Name</label>
                                <label className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 border-col pad-center worksheet-value">{data.data.clientDetails.userDisplayName}</label>
                                <label className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 border-col pad-center worksheet-field">Date</label>
                                <Calendar className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 worksheet-field pad-0" value={selectedDate} onChange={handleDateChange} minDate={minDate} showIcon dateFormat="dd/mm/yy" />
                            </div>
                            <div className="row">
                                <label className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 border-col pad-center worksheet-field">Site Agent Signature</label>
                                <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 border-col">
                                    <SignatureCanvas ref={signatureRef} penColor="black" throttle="12" canvasProps={{ width: 700, height: 90, className: "sigCanvas" }} />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default Sites;